import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/anthony/Projects/blog/src/components/blog-post-layout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Always a good start to the year. Long hard, often tales of people wandering Kinder lost. A test of how much the mince pies and wine have hampered you over the Christmas period.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I entered late and knew I was not fit enough to race but certainly fit enought to run. Not detered by the idea of slopping through mud for 24 miles the entry was in.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This race is a point to point race, from Marsden to Edale so transport is always a bit of a faff. I saw this as an opportunity to try out my new (to me) van. I went to the finish in Edale the evening before to sleep overnight as it would be an early start to leave cars and get to the start by 8am. I have to say my first night in the van was a great success. Loads of comfy sleep and even better was a huge amount of room for faffing in the morning.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The plan was simple...start slow and maintain it. After many years, I am starting to be able to manage this, however as a race tactic it is pretty rubbish. My overall fitness dictated that to make this anything other than absolutley miserable then this needed to be the way.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Setting off, hat on gloves on waterproof on. If I was going slow I wanted to be warm. The procession up to towards black hill was okay. Some massive puddles which for some strange reason everyone including me avoided, despite it just prolonging the inevitable drenching that was happening from the rain and he upcoing bogs, river crossings and general filth of the peak district.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Onto the paving slabs towards Black hill, this taking it easy lark is okay. Almost a pleasure compared to the eyeballs out pain of racing. Complacency was setting in, chatting about how the stne slabs were lethal and slippy meant that I was obviously going to fall on them. Waiting until there was some steps my legs flew up in the air and my bum landed on the edge of a step. I was quickly up and after a few metres of just checking myself I realised I got away with it.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I followed from Black hill and we probably took the wrong turn off the top and then the long slop through mud all the way to the finish began.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This year had a route change so we followed the pennine way up towards Bleaklow. The ideal route would be 1km on a bearing towards ...... but having not recced I too the safe route up to bleaklow and then the normal race route from there. It added distance but was a safer route. I think I lost a fair few places from those who managed to hit the direct line.`}</MDXTag>
      <MDXTag name="p" components={components}><figure className="gatsby-resp-image-figure" style={{}}>
    <span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "835px"
          }}>
      <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
  <picture>
        <source srcSet="/static/7240417f65aca8bab0c7342b8d71bd2f/1e598/bleaklow.webp 209w,
/static/7240417f65aca8bab0c7342b8d71bd2f/cd572/bleaklow.webp 418w,
/static/7240417f65aca8bab0c7342b8d71bd2f/2321d/bleaklow.webp 835w,
/static/7240417f65aca8bab0c7342b8d71bd2f/cd2f7/bleaklow.webp 1253w,
/static/7240417f65aca8bab0c7342b8d71bd2f/5afe4/bleaklow.webp 1670w,
/static/7240417f65aca8bab0c7342b8d71bd2f/93e07/bleaklow.webp 3840w" sizes="(max-width: 835px) 100vw, 835px" type="image/webp" />
        <source srcSet="/static/7240417f65aca8bab0c7342b8d71bd2f/46435/bleaklow.jpg 209w,
/static/7240417f65aca8bab0c7342b8d71bd2f/a2ddf/bleaklow.jpg 418w,
/static/7240417f65aca8bab0c7342b8d71bd2f/cba2b/bleaklow.jpg 835w,
/static/7240417f65aca8bab0c7342b8d71bd2f/5c835/bleaklow.jpg 1253w,
/static/7240417f65aca8bab0c7342b8d71bd2f/29c69/bleaklow.jpg 1670w,
/static/7240417f65aca8bab0c7342b8d71bd2f/d36d7/bleaklow.jpg 3840w" sizes="(max-width: 835px) 100vw, 835px" type="image/jpeg" />
        <img className="gatsby-resp-image-image" src="/static/7240417f65aca8bab0c7342b8d71bd2f/cba2b/bleaklow.jpg" alt="Bleaklow...I finally know where I am" title="Bleaklow...I finally know where I am" loading="lazy" style={{
                "width": "100%",
                "height": "100%",
                "margin": "0px",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0px",
                "left": "0px"
              }} />
      </picture>
    </span>
    <figcaption className="gatsby-resp-image-figcaption">Bleaklow...I finally know where I am</figcaption>
  </figure></MDXTag>
      <MDXTag name="p" components={components}>{`The run up to Kinder was uneventful enough. The slow strt made me cach up with a few people and a recent recce meant I took a great line towards the finish.`}</MDXTag>
      <MDXTag name="p" components={components}>{`COming in about a mile from the end, I saw Jasmin Paris up front. First person I had seen in a while and it gave me something to chase. I was catching towards the final desent but missed a line that Jasmin took for a beter shorter line into the final field. the chase was finally killed with a long slide down a sodden field on my arse.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    